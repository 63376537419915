:root {
	--black: #000;
	--grey: #666;
	--white: #fff;
	--red: #f00;

	--ws: 0.75rem;
}

@screen md {
	:root {
		--ws: 1.5rem;
	}
}

@screen xl {
	:root {
		--ws: 2.5rem;
	}
}

body {
	@apply font-times-now text-base;
	line-height: 1.3;
}
