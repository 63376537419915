@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import './modules/base.css';
@import './modules/base.fonts.css';
@import './modules/components.certificate.css';
@import './modules/components.logo.css';
@import './modules/components.menu.css';
@import './modules/components.gallery.css';
@import './modules/components.overview-gallery.css';
@import './modules/utilities.css';
@import './modules/utilities.fonts.css';
@import './modules/utilities.scrollbars.css';
