@layer components {

	.menu-button-labels .open,
	.menu-button:checked ~ .menu-button-labels .closed,
	.overview-menu-button:checked ~ .menu-button-labels .closed {
		display: none;
	}

	.menu-button:checked ~ .menu-button-labels  .menu-button-label .open,
	.overview-menu-button:checked ~ .menu-button-labels .overview-menu-button-label .open {
		display: block;
	}

	.menu,
	.overview-menu {
		opacity: 0;
		transition: opacity 0.3s;
		pointer-events: none;
		will-change: opacity;
	}

	.menu-button:checked ~ .menu,
	.overview-menu-button:checked ~ .overview-menu {
		opacity: 1;
		pointer-events: auto;
	}


	.client-list li {
		transition: transform .6s;
		will-change: transform;
		transform: translateX(-100%);
	}

	.menu-button:checked ~ .menu .client-list li {
		transform: translateX(0);
	}


}

.client-list li:nth-child(1) { transition-delay: 0.6s; }
.client-list li:nth-child(2) { transition-delay: 0.7s; }
.client-list li:nth-child(3) { transition-delay: 0.8s; }
.client-list li:nth-child(4) { transition-delay: 0.9s; }
.client-list li:nth-child(5) { transition-delay: 1.0s; }
.client-list li:nth-child(6) { transition-delay: 1.1s; }
.client-list li:nth-child(7) { transition-delay: 1.2s; }
.client-list li:nth-child(8) { transition-delay: 1.3s; }
.client-list li:nth-child(9) { transition-delay: 1.4s; }
.client-list li:nth-child(10) { transition-delay: 1.5s; }
.client-list li:nth-child(n+10) { transition-delay: 1.5s; }
