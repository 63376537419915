@layer components {

	.gallery {
/* NOTE: THIS LEADS TO INCORRECT .scrollTop INFO ON SAFARI! */
/*		scroll-behavior: smooth; */
	}

	.gallery-image-full {
		opacity: 0;
		transition: opacity 0.2s ease-in;
	}

	.animation-complete {
		opacity: 0;
	}

	.animation-complete + .gallery-image-full,
	.animation-complete + picture .gallery-image-full {
		opacity: 1;
	}

	.gallery-image-left,
	.gallery-image-right {
		will-change: transform;
		transition: opacity 0.2s ease-out 0.2s;
	}

	.gallery-image-1\/2 {
		max-width: calc(50% - .5 * var(--ws));
	}

	.gallery-video iframe {
		width: 100%;
		height: 100%;
	}

	.gallery-image-caption {
		position: absolute;
		opacity: 0;
		transition: 0.5s opacity;
		font-size: 12px;
	}

}

.imgfit {
	flex-grow: 0 !important;
	flex-shrink: 0 !important;
}

@media ( min-width: 768px ) {

	.imgfit:has(.animation-complete) .gallery-image-caption {
		left: -1.5em;
		top: 0;
		width: 100%;
		overflow: hidden;
		opacity: 1;
		height: 1.25em;
		font-size: 14px;
		line-height: 1.25em;
		white-space: nowrap;

		transform: translateX(-100%) rotate(-90deg);
		transform-origin: top right;
	}

}
