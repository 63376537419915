.certificate {
	@apply font-times-now text-lg;

	h3 {
		@apply titling uppercase;
		font-size: 12vw;
		line-height: 0.9;
		margin: 1.35em 0 .45em;
	}

	h3:first-child {
		margin-top: 0;
	}

	h4 {
		@apply text-xl;
	}

	* + h4,
	* + p {
		margin-top: 1em;
	}

	p:empty {
		display: none;
	}
}

.certificate-logo {

	padding: calc( 0.5 * var(--ws) ) var(--ws);

}

@media screen and (min-width: 768px) {

	.certificate {

		@apply p-ws-2 text-xl;

		h3 {
			font-size: 7vw;
			line-height: 0.9;
		}

	}

}

@media screen and (min-width: 1280px) {

	.certificate {

		@apply text-3xl;

		h4 {
			@apply text-3xl;
		}

	}

}

@media print, screen and (min-width: 768px) {

	.certificate {

		display: grid;
		grid-template-columns: repeat(8, 1fr);
		row-gap: 1em;

		h3 {
			grid-column-start: 1;
			grid-column-end: 9;
			max-width: 37.5%;
			margin: 0;
		}

		h4 {
			grid-column-start: 1;
			grid-column-end: 4;
			margin: 0;

			font-size: inherit;
		}

		p {
			grid-column-start: 4;
			grid-column-end: 9;
			margin: 0;
		}

		h3 + p {
			margin: 4em 0;
		}

	}

}

@media print {

	.certificate {

		font-size: 12.5pt;
		line-height: 1.2;

		h3 {
			font-size: 42pt;
			line-height: 0.833;
		}

		h3 + p {
			margin: 6em 0;
		}

	}

	.certificate-logo {
		position: fixed;
		bottom: 0;
		padding: 0;
	}

}

