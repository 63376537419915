@font-face {
  font-family: "Times Now";
  src: url('../fonts/times-now-extralight-subset.woff2') format('woff2'), url('../fonts/times-now-extralight-subset.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: "Times Now";
  src: url('../fonts/times-now-semilight-subset.woff2') format('woff2'), url('../fonts/times-now-semilight-subset.woff') format('woff');
  font-weight: 300;
}
