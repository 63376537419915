@layer components {
	.overview-gallery {
		display: flex;
		flex-wrap: wrap;
	}

	.overview-gallery > * {
		display: block;
		height: 25vw;
	}

	@screen md {
		.overview-gallery > * {
		display: block;
			height: 25vw;
		}
	}

}
